import React from 'react'
import './Loading.css';

const Loading = () => {
    return (
        <div className="preload" data-preloaded>
            <div className="circle"></div>
            <p className="text">Arcs and Sparks Technology</p>
            
        </div>
    )
}

export default Loading
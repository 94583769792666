import React from 'react'

const Header = () => {

    const headerStyle = {
       'position' : 'relative',
        'margin' : '16px',
        'height': '2.5rem',
        'background-color': 'var(--header-grey)',
        'z-index': '30',
        'border-radius' : '8px',
        'border' : '3px solid var(--black)',
        'box-shadow' : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    };

    const textStyle = {
        
        'text-align' : 'center',
        'margin' : '0',
        'vertical-align' : 'middle',
        'line-height' : '2.5rem',
        'font-size' : '1.5rem',
        'font-weight' : 'bold',
    };

    return (
        <header style={headerStyle}>
            <p style={textStyle}>Handyman Services Bath</p>
        </header>
    )
}

export default Header
import Header from './Header';
import Loading from './Loading';
import Footer from './Footer';



function App() {
  return (
    <div className="App">     
      <Header/>
      {<Loading></Loading>}
      <Footer/>
    </div>    
  );
}

export default App;

import React from 'react'

const Footer = () => {

    const footerStyle = {
        'position' : 'absolute',
        'bottom' : '0',
        'width'  : '100%',
        'height' : '10rem',
        'background-color' : 'var(--footer)',
        'z-index' : '20'
    };

    const yellowBannerStyle= {
        'background-color' : 'var(--logo-yellow)',
        'margin' : '0',
        'height': '1.0rem',  
        'border' : '1px solid var(--black-alpha_15 )',
        'text-align' : 'center',
        'padding' : '5px',
        'font-weight' : 'bold',
        'color' : 'var(--black-alpha_80 )'
    };

    const textStyle= {
        'margin-top' : '2.5rem',
        'color' : 'var(--white)', 
        'text-align' : 'center',
        'font-size' : '1.3rem'
    
    };

    const copyrightStyle= {
        'margin-top' : '2.5rem',
        'margin-bottom' : '0',
        'color' : 'var(--white)', 
        'text-align' : 'center',
       'font-size' : '0.8rem'
        
    };

   

    const today = new Date();

    return (
        <footer style={footerStyle}>
            <p style={yellowBannerStyle}>Digital Services</p>
            <p style={textStyle}>Arcs and Sparks Technology Ltd.</p>
            <p style={copyrightStyle}>Copyright &copy; {today.getFullYear()}</p>
        </footer>
    )
}

export default Footer